<template>
  <v-app>
  <div>
    <title>แลกของรางวัล</title>
    <img src="../assets/image/UI Member PHOTO/Banner-06.png" class="img-fluid" alt="Responsive image" style="width: 100%;" />

    <div style="width: 95%; margin: 10px auto 0 auto;">
      <CCard>
        <CCardBody>
          <div>
            <h1  style="font-size:16px;">กรอก Code ของคุณ</h1>
            <CInput type="text" v-model="codetrueyou" @input="checkcodeTrueYou()" placeholder="Code TrueYou" aria-label="Code TrueYou" aria-describedby="basic-addon1"></CInput>
            <template v-if="checkCode">
                  <p style="color:#FF0000; margin: 0 0 0 0;">{{checkText}}</p>
            </template>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <v-dialog
        v-model="warningModal"
        persistent
        width="100%"
      >
        <v-card>
          <div style="padding: 10px;">
            <div class="d-flex" style="width: 80%; margin: auto;">
              <div style="width: 70%;">
                <h1  style="font-size:14px; margin: 0 0;">ยินดีด้วยค่ะ ,</h1> 
                <h1  style="font-size:14px;">คุณได้รับสิทธิพิเศษจาก</h1>
                <h1  style="font-size:16px;">TRUE YOU</h1>
              </div>
              <div style="width: 30%;">
                <center>
                  <img src="../assets/image/UI Member PHOTO/Reward icon-02.png" class="img-fluid" alt="Responsive image" style="width: 80%;" />
                </center>
              </div>
            </div>
            <CCard style="width: 90%; margin: auto;">
              <CCardBody>
                <div>
                  <center>
                    <!-- <h1 style="font-size:20px; ">{{popup.details}}</h1> -->
                    <h1 style="font-size:20px; ">{{popup.detailsname}}</h1>

                    <div class="d-flex justify-content-center">
                      <p  style="font-size:22px; margin: 0 5px 0 0; ">มูลค่า {{popup.value}}</p>
                      <p  style="font-size:16px; margin: 7px 0 0 0; "> บาท</p>
                    </div>
                    <p  style="font-size:12px; color:#FF0000;"> หมดอายุ {{popup.expiration}} </p>
                  </center>
                </div>
              </CCardBody>
            </CCard>
            <center>
              <CButton v-on:click="closeCModal()" style="background-color:#8350ff; color:#FFFFFF; margin: 10px 10px "><p style="margin: 0;">ยืนยัน</p></CButton>
            </center>
          </div>
        </v-card>
    </v-dialog>

  </div>
  </v-app>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";

import { freeSet } from "@coreui/icons";
import config from '../config'
const axios = require("axios");

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
      warningModal: false,
      regisModal: false,
      selected: "Month",
      name: "AOF",
      expiration: "00/00/00000",
      nameUser: "คุณ วันเดอร์ ซักสะอาด",
      codetrueyou:"",
      profile:"",
      checkCode:false,
      checkText:"",
      tabs: null,
      popup:{},
    };
  },
  mounted() {
    this.regisModal = this.$store.state.popupregis
    console.log("this.regisModal",this.regisModal)
    this.profile = this.$store.state.profile

    console.log("profile",this.$store.state.profile)
  },
  methods: {
    checkcodeTrueYou(){
      console.log(this.codetrueyou.substring(0,2))
      if(this.codetrueyou.length == 8){
        let data = {
                "userid": this.profile.userId,
                "branch": this.profile.branch,
                "numberphone":this.profile.numberphone,
                "detailsname": "ส่วนลดจาก TRUE YOU",
                "detailsbrand": "trueyou",
                "detailscodeRef": this.codetrueyou
            }
        this.API_checkpromotion(data).then((res) => {
          console.log("ooo",res)
    
          this.popup = res.data


          this.popup["detailsname"] = "ส่วนลดจาก TRUE YOU"
          console.log("this.popup ",this.popup
                )
          // this.detailsname = res.config.data.detailsname


          // this.expiration = res.data.expiration
          if(res.data.status == "code ready to use"){
            this.warningModal = true
            this.checkCode = false
          }
          else if(res.data.status == "code used"){
            this.warningModal = false
            this.checkCode = true
            this.checkText = "*code นี้ถูกใช้ไปแล้ว"
          }
          else if(res.data.status == "no code"){
            this.warningModal = false
            this.checkCode = true
            this.checkText = "*code ไม่ถูกต้อง"
          }
        })
      }
    },
    scanCode() {
      liff.scanCode().then(result => {
        const stringifiedResult = JSON.stringify(result);
        alert(stringifiedResult);
      });
    },
    closeCModal(){
      this.warningModal = false
      this.$router.push('/rewardww')
    },
    API_checkpromotion(data){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server+"/checkcodepomotion", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
  },
};
</script>
